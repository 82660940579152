import fetch from "cross-fetch"
import { getUser } from "../services/auth"
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from "@apollo/client"

const httpLink = new HttpLink({
  uri: "https://wptest.shoonyavr.com/graphql",
  fetch,
})

// const httpLink = new HttpLink({
//   uri: "http://localhost/wordpress/graphql",
//   fetch,
// })

const userInfo = getUser()

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const { operationName } = operation

  if (operationName === "LOGIN") {
    return forward(operation)
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${userInfo.authToken}` || null,
    },
  }))
  // console.log(operation)

  return forward(operation)
})

export const client = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache(),
})
