export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("userInfo")
    ? JSON.parse(window.localStorage.getItem("userInfo"))
    : {}

export const setUser = user =>
  window.localStorage.setItem("userInfo", JSON.stringify(user))

export const isLoggedIn = () => {
  const user = getUser()  
  if (user.user) {
    return !!user.user.id
  }
  return false
}

export const logout = callback => {
  setUser({})
  callback()
}
