// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-cobie-js": () => import("./../../../src/pages/cobie.js" /* webpackChunkName: "component---src-pages-cobie-js" */),
  "component---src-pages-dfx-form-js": () => import("./../../../src/pages/dfx-form.js" /* webpackChunkName: "component---src-pages-dfx-form-js" */),
  "component---src-pages-gamipress-js": () => import("./../../../src/pages/gamipress.js" /* webpackChunkName: "component---src-pages-gamipress-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-js": () => import("./../../../src/pages/modal.js" /* webpackChunkName: "component---src-pages-modal-js" */),
  "component---src-pages-pdf-processor-js": () => import("./../../../src/pages/pdf-processor.js" /* webpackChunkName: "component---src-pages-pdf-processor-js" */),
  "component---src-pages-pdf-viewer-js": () => import("./../../../src/pages/pdf-viewer.js" /* webpackChunkName: "component---src-pages-pdf-viewer-js" */),
  "component---src-pages-upload-csv-js": () => import("./../../../src/pages/upload-csv.js" /* webpackChunkName: "component---src-pages-upload-csv-js" */)
}

